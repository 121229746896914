import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Top, Mission, Vision, Features, HIWorks, FAQ, Footer } from './components';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import icon from './icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './App.css';

function App() {
  const [show, setShow] = useState(false);

  return (
    <BrowserRouter>
      <div className="App">
        <div className="header">
          <Container className="header-big">
            <span>
              <img src={icon} alt="buzz-bee" className="nav-icon" />
            </span>
            <span className="nav-renders">
              <Link to="mission" smooth={true} duration={500} className="nav-txt">
                Mission
              </Link>
              <Link to="vision" smooth={true} duration={500} className="nav-txt">
                Vision
              </Link>
              <Link to="features" smooth={true} duration={500} className="nav-txt">
                Features
              </Link>
              <Link to="hiworks" smooth={true} duration={500} className="nav-txt">
                How it works
              </Link>
              <Link to="faq" smooth={true} duration={500} className="nav-txt">
                FAQs
              </Link>
              <Link to="contact" smooth={true} duration={500} className="nav-txt">
                Contact
              </Link>
            </span>
          </Container>
          <Container className="header-small">
            <span className="icon-left">
              <img src={icon} alt="buzz-bee" className="nav-icon" />
            </span>
            <span className="icon-right" onClick={() => setShow(!show)}>
              <FontAwesomeIcon icon={faBars} size="xl" />
            </span>
            {show ? (
              <center>
                <br />
                <Link to="mission" smooth={true} duration={500} className="nav-txt">
                  Mission
                </Link>
                <Link to="vision" smooth={true} duration={500} className="nav-txt">
                  Vision
                </Link>
                <Link to="features" smooth={true} duration={500} className="nav-txt">
                  Features
                </Link>
                <Link to="hiworks" smooth={true} duration={500} className="nav-txt">
                  How it works
                </Link>
                <Link to="faq" smooth={true} duration={500} className="nav-txt">
                  FAQs
                </Link>
                <Link to="contact" smooth={true} duration={500} className="nav-txt">
                  Contact
                </Link>
              </center>
            ) : null}
          </Container>
        </div>
        <Routes>
        <Route path="/" element={[<Top />, <Mission name="mission"/>, <Vision name="vision"/>, <Features name="features"/>, <HIWorks name="hiworks"/>, <FAQ name="faq"/>, <Footer name="footer"/>]} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
