import React from 'react'
import './footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLinkedin,
    faInstagram,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faHeart } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <footer className="footer">
            <div>Contact at:</div>
            <samp className="footer-mail"><a href="mailto:info@buzzrize.com" className="footer-mail-txt">info@buzzrize.com</a></samp>
            <p className="footer-socials">
                <a href="https://www.linkedin.com/company/buzzrize" target="_blank" rel="noopener noreferrer" className="footer-a"><FontAwesomeIcon icon={faLinkedin} size="xl" className="footer-icon" /></a>
                <a href="https://www.instagram.com/buzzrize/" target="_blank" rel="noopener noreferrer" className="footer-a"><FontAwesomeIcon icon={faInstagram} size="xl" className="footer-icon" /></a>
                <a href="https://twitter.com/buzzrize" target="_blank" rel="noopener noreferrer" className="footer-a"><FontAwesomeIcon icon={faTwitter} size="xl" className="footer-icon" /></a>
            </p>
            <p className="footnote">Made with <FontAwesomeIcon icon={faHeart} className="footer-heart" /> by <a href="https://webminix.com/" target="_blank" rel="noopener noreferrer">WEBMINIX</a></p>
        </footer>
    )
}

export default Footer
