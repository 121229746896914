import React, { useState } from 'react';
import './hiworks.css'
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faKey, faLightbulb, faLock, faMagnifyingGlass, faQuoteLeft, faQuoteRight, faRocket, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
import {
    faFacebook,
    faInstagram,
    faTiktok,
    faTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { db } from "../../firebase";
import Swal from 'sweetalert2'


const HIWorks = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [facebook, setFacebook] = useState("");
    const [twitter, setTwitter] = useState("");
    const [tiktok, setTiktok] = useState("");
    const [other, setOther] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = (e) => {
        if (!name?.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Authentication Error',
                text: 'Name cannot be empty',
              })
            return
        }

        db.collection("Waitlist")
            .add({
                name: name,
                email: email,
                number: number,
                instagram: instagram,
                youtube: youtube,
                facebook: facebook,
                twitter: twitter,
                tiktok: tiktok,
                other: other,
                timestamp: Date.now()
            })
            .then(() => {
                setShow(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Thank you!',
                    text: 'We will get back to you as soon as your application is reviewed!',
                  })
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Authentication Error',
                  })
                setShow(false);
            });

        setName("");
        setEmail("");
        setNumber("");
        setInstagram("");
        setYoutube("");
        setFacebook("");
        setTwitter("");
        setTiktok("");
        setOther("");
    };

    return (
        <div className="hiworks">
            <p className="hiworks-h">How it works?</p>
            <VerticalTimeline className="hiworks-tl">
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#8736B4', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #8736B4' }}
                    date={<span className="change">Let's start here<br />Please mention all your social handles it helps us in the review process</span>}
                    iconStyle={{ background: '#8736B4', color: '#fff' }}
                    icon={<FontAwesomeIcon icon={faLock} size="xl" className="hiworks-icon" />}
                >
                    <h3 className="vertical-timeline-element-title">Apply for Access</h3>
                    <p>
                        Lock in your spot by applying for access to create your own personalized app. Fill out our application form. Our team will review your application and get back to you within 48 hours to spill the tea on the next steps.
                    </p>
                    <br />
                    <Button className="hiworks-button" onClick={handleShow}>Join the waitlist</Button>
                    <br />
                    <br />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<span>You can always drop in a mail, to see whether offline meetup is possible or not.<br />We would love to meet you</span>}
                    iconStyle={{ background: '#8736B4', color: '#fff' }}

                    icon={<FontAwesomeIcon icon={faLightbulb} size="xl" className="hiworks-icon" />}>
                    <h3 className="vertical-timeline-element-title">Consultation and Requirements Gathering</h3>
                    <p>
                        Once your application is accepted, our team will schedule a virtual meetup to dive deep into your needs and app desires. We'll gather all the details about your branding, features, content, and any customizations you want.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<span>You are going to LOVE it!</span>}
                    iconStyle={{ background: '#8736B4', color: '#fff' }}

                    icon={<FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="hiworks-icon" />}>
                    <h3 className="vertical-timeline-element-title">Review and Feedback</h3>
                    <p>
                        The moment of truth! We'll unveil a sneak peek of your app. Take your time to explore and interact with it from every angle. We're all ears for your feedback, suggestions, and any changes you'd like to make. We're here to make your app dreams come true.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={<span>Yayayyy!</span>}
                    iconStyle={{ background: '#8736B4', color: '#fff' }}

                    icon={<FontAwesomeIcon icon={faRocket} size="xl" className="hiworks-icon" />}>
                    <h3 className="vertical-timeline-element-title">App Launch on PlayStore and AppStore</h3>
                    <p>
                        We'll launch your app on both the PlayStore and AppStore, making it available to users worldwide
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date={<span>Well, You OWN it!</span>}
                    iconStyle={{ background: '#EEEBFF', color: '#000' }}

                    icon={<FontAwesomeIcon icon={faKey} size="xl" className="hiworks-icon" />}>
                    <h3 className="vertical-timeline-element-title">You're in Control</h3>
                    <p>
                        At BuzzRize, we believe in putting the power in your hands. We trust your judgment and respect your vision. It's your platform, and you have the final say in ensuring it aligns with your values and goals.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date={<span>We are there for you</span>}
                    iconStyle={{ background: '#EEEBFF', color: '#000' }}

                    icon={<FontAwesomeIcon icon={faScrewdriverWrench} size="xl" className="hiworks-icon" />}>
                    <h3 className="vertical-timeline-element-title">App Maintenance and Support</h3>
                    <p>
                        Our support team will be on standby to provide ongoing maintenance and support. We'll keep your app updated, secure, and compatible with the latest operating systems.
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
            <p className="hiworks-footnote"><FontAwesomeIcon icon={faQuoteLeft} size="2x" className="hiworks-icon-left" />Oh, did we forget to tell you? All of this is for free!<FontAwesomeIcon icon={faQuoteRight} size="2x" className="hiworks-icon-right" /></p>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Join the waitlist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <p>Tell us more about yourself, so we can understand you better!</p>
                        <Form.Label><span>Name</span><span></span></Form.Label>
                        <Form.Control className="modal-up" type="text" placeholder="Enter your name" required value={name} onChange={(e) => setName(e.target.value)} />
                        <Form.Label><span>E-mail</span><span></span></Form.Label>
                        <Form.Control className="modal-up" type="email" placeholder="Enter your E-mail ID" required value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Form.Label><span>Phone Number</span><span></span></Form.Label>
                        <Form.Control className="modal-up" type="text" placeholder="Enter your phone number" value={number} onChange={(e) => setNumber(e.target.value)} />
                        <Form.Label>Social media accounts</Form.Label>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faInstagram} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faYoutube} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Youtube" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faFacebook} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Facebook" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faTwitter} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Twitter" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faTiktok} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Tiktok" value={tiktok} onChange={(e) => setTiktok(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faPlus} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Other" value={other} onChange={(e) => setOther(e.target.value)} />
                        </InputGroup>
                        <br />
                        <center>
                            <Button type="submit">Submit</Button>
                        </center>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default HIWorks
