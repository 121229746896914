import React from 'react'
import './features.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col } from 'react-bootstrap'
import { faCoins, faFaceSmile, faGift, faHandshake, faMobileScreenButton, faWrench } from '@fortawesome/free-solid-svg-icons'

const Features = () => {
    return (
        <Container className="features">
            <center>
                <p className="features-h">We Provide</p>
                <Row className="features-row">
                    <Col className="feature">
                        <div className="feature-icon-border"><FontAwesomeIcon icon={faMobileScreenButton} className="feature-icon" /></div>
                        <div className="feature-h">Dedicated App</div>
                        <div className="feature-txt">
                            Each Creator / community leader will have a dedicated app where they can easily engage with their followers.
                        </div>
                    </Col>
                    <Col className="feature">
                        <div className="feature-icon-border"><FontAwesomeIcon icon={faGift} className="feature-icon" /></div>
                        <div className="feature-h">User Rewards</div>
                        <div className="feature-txt">
                            Your users will be rewarded by our partner brands for being active and engaging on your platform.
                        </div>
                    </Col>
                    <Col className="feature">
                        <div className="feature-icon-border"><FontAwesomeIcon icon={faWrench} className="feature-icon" /></div>
                        <div className="feature-h">Customizations</div>
                        <div className="feature-txt">
                            You are in control, you control how your app looks, what features it should have to the content policy you decide for your platform.
                        </div>
                    </Col>
                </Row>
                <Row className="features-row">
                    <Col className="feature">
                        <div className="feature-icon-border"><FontAwesomeIcon icon={faHandshake} className="feature-icon" /></div>
                        <div className="feature-h">Brand Collaborations</div>
                        <div className="feature-txt">
                            You will get access to our partner brands where you can get more opportunities for collaborations.
                        </div>
                    </Col>
                    <Col className="feature">
                        <div className="feature-icon-border"><FontAwesomeIcon icon={faFaceSmile} className="feature-icon" /></div>
                        <div className="feature-h">User Engagement</div>
                        <div className="feature-txt">
                            You being at the center stage of the application, It gives you a wide array of opportunities to engage better with your followers.
                        </div>
                    </Col>
                    <Col className="feature">
                        <div className="feature-icon-border"><FontAwesomeIcon icon={faCoins} className="feature-icon" /></div>
                        <div className="feature-h">Monetization Options</div>
                        <div className="feature-txt">
                            You will have multiple revenue streams, starting from the In-app advertisements, Partner Brand Deals, User Subscriptions and a personalized Merch Store.
                        </div>
                    </Col>
                </Row>
            </center>
        </Container>
    )
}

export default Features
