import React from 'react'
import './faq.css'
import { Accordion , Container} from 'react-bootstrap'

const FAQ = () => {
    return (
        <Container className="faq">
            <p className="faq-h">Frequently Asked Questions</p>
            <Accordion defaultActiveKey="0" variant='light'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>What is BuzzRize?</Accordion.Header>
                    <Accordion.Body>
                        BuzzRize is a platform that allows you to create your own personalized app, tailored to your specific needs and preferences. It provides a range of features and functionalities to help you design, develop, and launch your app.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>How does BuzzRize work?</Accordion.Header>
                    <Accordion.Body>
                        BuzzRize works by providing you with an intuitive app creation process. You apply for access, customize your app based on your requirements, and launch it on the PlayStore and AppStore. BuzzRize offers ongoing maintenance and support to ensure your app remains up-to-date and secure.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>How can I apply for access to create my personalized app?
                    </Accordion.Header>
                    <Accordion.Body>
                        To apply for access, simply fill out our application form with the necessary details about your app idea and requirements. Our team will review your application and get back to you within 48 hours to discuss the next steps.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>How much control do I have over the customization and branding of my app?
                    </Accordion.Header>
                    <Accordion.Body>
                        You have full control over the customization and branding of your app. BuzzRize provides a range of tools and options to personalize your app's design, layout, colors, logo, and other visual elements. You can ensure that your app aligns perfectly with your brand identity and vision.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Is BuzzRize available worldwide?</Accordion.Header>
                    <Accordion.Body>
                        Yes, BuzzRize is available worldwide. We welcome creators from all around the globe to join our platform and create their personalized apps.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Is BuzzRize compatible with iOS and Android?</Accordion.Header>
                    <Accordion.Body>
                        Yes, BuzzRize is compatible with both iOS and Android platforms. You can create and launch your app on both the PlayStore and AppStore, ensuring wider accessibility for your users.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Can I use BuzzRize to fundraise for charitable causes or projects?
                    </Accordion.Header>
                    <Accordion.Body>
                        Yes, we provide that option as well, you just have to let us know during the discovery call.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>Is BuzzRize a free platform?</Accordion.Header>
                    <Accordion.Body>
                        Yes, BuzzRize is absolutely free to use. We do not charge any fees for accessing and utilizing the platform's core features.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>What aspects of BuzzRize are free?</Accordion.Header>
                    <Accordion.Body>
                        All the features and functionalities of BuzzRize are available to users at no cost. This includes app creation, customization, app launch on PlayStore and AppStore, and ongoing maintenance and support.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>Are there any hidden charges or subscription fees?</Accordion.Header>
                    <Accordion.Body>
                        No, there are no hidden charges or subscription fees associated with using BuzzRize. You can access and utilize the platform's features without any financial commitments.
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}

export default FAQ
