import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './mission.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import m2 from '../../mock2.svg'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'

const Mission = () => {
    return (
        <div className="mission">
            <Row className="mission-content">
                <Col className="mission-il">
                    <img src={m2} alt="mobile-mockup" className="mission-mock" />
                </Col>
                <Col className="mission-txt">
                    <p className="mission-h">Our Mission</p>
                    <p className="mission-subh"><FontAwesomeIcon icon={faQuoteLeft} size="2x" className="mission-icon-left"/>To challenge the status quo of social networking sites and bring the power back to the users / creators<FontAwesomeIcon icon={faQuoteRight} size="2x" className="mission-icon-right"/></p>
                    <p className="mission-f">Your <span className="mission-f-emph">OWN</span> space to make an impact is here</p>
                </Col>
            </Row>
        </div>
    )
}

export default Mission
