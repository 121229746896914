import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBjm1lmDXtuIfH77y8rWd-ECzAeV5zo_30",
    authDomain: "buzzrize-webminix.firebaseapp.com",
    projectId: "buzzrize-webminix",
    storageBucket: "buzzrize-webminix.appspot.com",
    messagingSenderId: "453615847626",
    appId: "1:453615847626:web:841de245002319fe798c33",
    measurementId: "G-8GWKQGNK9X"
  });

var db = firebaseApp.firestore();

export { db };