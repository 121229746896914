import React from 'react'
import './vision.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'

const Vision = () => {
    return (
        <center>
            <div className="vision">
                <p className="vision-h">Our Vision</p>
                <p className="vision-txt"><FontAwesomeIcon icon={faQuoteLeft} size="2x" className="vision-icon-left"/>To build a global network that transcends boundaries, enabling diverse communities to connect, share ideas, and collaborate freely<FontAwesomeIcon icon={faQuoteRight} size="2x" className="vision-icon-right"/></p>
            </div>
        </center>
    )
}

export default Vision
