import React, { useState } from 'react';
import { Button, Modal, Form, InputGroup } from 'react-bootstrap'
import m1 from '../../mock1.svg'
import './top.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFacebook,
    faInstagram,
    faTiktok,
    faTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { db } from "../../firebase";
import Swal from 'sweetalert2'

const Top = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [facebook, setFacebook] = useState("");
    const [twitter, setTwitter] = useState("");
    const [tiktok, setTiktok] = useState("");
    const [other, setOther] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name?.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Authentication Error',
                text: 'Name cannot be empty',
              })
            return
        }

        db.collection("Waitlist")
            .add({
                name: name,
                email: email,
                number: number,
                instagram: instagram,
                youtube: youtube,
                facebook: facebook,
                twitter: twitter,
                tiktok: tiktok,
                other: other,
                timestamp: Date.now()
            })
            .then(() => {
                setShow(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Thank you!',
                    text: 'We will get back to you as soon as your application is reviewed!',
                  })
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Authentication Error',
                  })
                setShow(false);
            });

        setName("");
        setEmail("");
        setNumber("");
        setInstagram("");
        setYoutube("");
        setFacebook("");
        setTwitter("");
        setTiktok("");
        setOther("");
    };

    return (
        <div className="top">
            <center>
                <div className="waitlist">
                    <p className="waitlist-txt"><span className="brand-name">BuzzRize</span> brings you a groundbreaking <span className="emph">Social Networking Platform</span> that puts creators at the center stage.<br /> Say goodbye to platform bias, limited monetization options, and lack of customization. Join us to experience a rewarding social media revolution and build your own brand without any cost.</p>
                    <Button className="btn waitlist-btn" onClick={handleShow}>Join the waitlist</Button>
                    <br />
                    <img className="waitlist-mock" src={m1} alt="mobile-mockup" />
                </div>
            </center>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Join the waitlist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <p>Tell us more about yourself, so we can understand you better!</p>
                        <Form.Label><span>Name</span><span></span></Form.Label>
                        <Form.Control className="modal-up" type="text" placeholder="Enter your name" required value={name} onChange={(e) => setName(e.target.value)} />
                        <Form.Label><span>E-mail</span><span></span></Form.Label>
                        <Form.Control className="modal-up" type="email" placeholder="Enter your E-mail ID" required value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Form.Label><span>Phone Number</span><span></span></Form.Label>
                        <Form.Control className="modal-up" type="text" placeholder="Enter your phone number" value={number} onChange={(e) => setNumber(e.target.value)} />
                        <Form.Label>Social media accounts</Form.Label>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faInstagram} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faYoutube} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Youtube" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faFacebook} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Facebook" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faTwitter} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Twitter" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faTiktok} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Tiktok" value={tiktok} onChange={(e) => setTiktok(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><center><FontAwesomeIcon icon={faPlus} size="lg" className="modal-icon" /></center></InputGroup.Text>
                            <Form.Control placeholder="Other" value={other} onChange={(e) => setOther(e.target.value)} />
                        </InputGroup>
                        <br />
                        <center>
                            <Button type="submit">Submit</Button>
                        </center>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Top
